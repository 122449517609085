
@include breakpoint(medium down) {

  .header .logo {
    width: 17%;
  }

  .link-box-wrap.veranstaltungen,
  .link-box-wrap.big {
    .link-box .link-box-link-wrap {
      position: static;
      transform: none;
    }
  }
}


@include breakpoint(small down) {

  .header .logo {
    width: 30%;
  }

  .slick-dots,
  .slick-prev,
  .slick-next {
    display: none !important;
  }

  .teaser {
    .cell {
      margin-bottom: 1rem;
    }
  }

  .link-box-wrap {
    .link-box .link-box-link-wrap {
      position: static;
      transform: none;
      padding: 1.5rem;
    }

    .link-box .image {
      min-height: 200px;
    }
  }


  .submit-btn {
    margin-top: 1.5rem;
    float: none !important;

    input {
      width: 100%;
    }
  }

  .footer {
    text-align: center;

    .menu {
      float: none;
      margin-top: 1.5rem;
    }
  }
}
