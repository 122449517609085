.main-menu {

  .dropdown.menu {
    li {
      a {
        border-right: 1px solid #e1e1e1;
        border-bottom: 2px solid transparent;
        padding: 1.5rem 1.8rem;
        text-transform: uppercase;


        &.active,
        &:hover {
          background-color: #f7f7f7;
          border-bottom: 2px solid $black;
        }
      }

      &:first-child {
        a {
          border-left: 1px solid #e1e1e1;
        }
      }

      &.is-active {
        > a {
          background-color: #f7f7f7;
          border-bottom: 2px solid $black;
        }
      }
    }
  }

  .is-dropdown-submenu-parent {
    position: static;
  }

  .is-dropdown-submenu.menu {
    width: 100%;

    li {
      text-align: left !important;
      a {
        color: $black;
      }
    }
  }
}

// Off-Canvas

.off-canvas-btn {
  padding: 1rem;
  text-align: right;
}


.off-canvas {
  padding-top: 3.5rem;

  li {
    display: block;
    width: 100%;

    a {
      border-right: 0 none;
      border-bottom: 0 none;
      padding: 1rem 0.8rem;
      text-transform: uppercase;
      color: $black;


      &.active,
      &:hover {
        background-color: transparent;
        border-bottom: 0 none;
      }
    }

    .menu {
      padding-left: 1rem;
    }
  }
}