// Teaser Box

.teaser {
  position: relative;

  .teaser-image {
    img {
      opacity: 0.5;
    }
  }

  a:hover {
    .teaser-image {
      img {
        opacity: 1;
      }
    }
  }

  .teaser-text {
    background: $primary-color;
    display: block;
    text-align: center;
    text-transform: uppercase;
    color: $white;
    padding: 1rem 0;
  }
}


// Link Box

.link-box-wrap {
  background: #eef7f9 url('../img/bg02.png') no-repeat 0 0;
  background-size: cover;
  background-position: center;
  padding: rem-calc(80) 0 rem-calc(35);
}

.link-box {
  background: $white;
  margin-bottom: rem-calc(35);
  position: relative;
  box-shadow: $box-shadow;

  h3 {
    font-weight: 400;
  }

  .cell {
    position: relative;
  }

  &.re {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  .image {
    min-height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .link-box-link-wrap {
    @include absolute-center;
  }

  .link {
    padding-top: 0.5rem;
  }
}

// Big Linkbox


.link-box-wrap.big {
  .link-box-link-wrap {
    width: 100%;
    padding: 1.5rem;
  }

  .link-box {
    background-color: $primary-color;
    color: $white;

    a,
    h3 {
      color: $white;
    }
  }
}


.link-box-wrap.veranstaltungen {
  .link-box-link-wrap {
    width: 100%;
    padding: 1.5rem;
  }
}