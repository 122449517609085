.header {

  .grid-container {
    position: relative;
  }

  .logo {
    position: absolute;
    top: 0;
    left: 0.9375rem;
    z-index: 99;
    padding: 1rem;
    background: $white;
    box-shadow: $box-shadow;
  }
}