.footer {
  border-top: 5px solid #e1e1e1;

  .footer-menu {
    margin-bottom: 14px;
  }

  .menu {
    display: inline-block;
    float: right;
    border-top: 2px solid $black;

    &.align-right.vertical li {
      display: inline-block;
    }
  }
}